import React from "react";
import './EventsList.css';

// styles
const eventStyle = {
}
const directionStyle = {
    margin: '20px',
    // textAlign: 'center'
}

// markup
const FeedList = ({ eventsList }) => {


    return (
        <>
            {
                eventsList && eventsList.length > 0 ?
                    <div style={eventStyle}>
                        {
                            eventsList.map(event => {

                                return (
                                    <div className={event.severity == 'MAJOR' ? 'eventPanel majorPanel' : 'eventPanel minorPanel'}>
                                        {/* <h2>
                                            Coquihalla (Hwy 5) Status: {" "}
                                            <u className={event.closed == 'OPEN' ? 'open' : 'closed'}>
                                                {event.closed + " " + direction}
                                            </u>
                                        </h2> */}
                                        <h3>Description: </h3>
                                        <p>
                                            {event.description}
                                        </p>
                                    </div>
                                )
                            })
                        }
                    </div>
                    :
                    <div>no events</div>
            }
        </>
    )
}

export default FeedList
